import { Bar,   mixins } from 'vue-chartjs'

export default {
  extends: Bar,
  props: {
    big: {
      type: Boolean,
      default: false,
    },
    responsive: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    options() {
      return {
        responsive: this.responsive,
        maintainAspectRatios: this.responsive,
        scales: {
            xAxes: [ { stacked: true } ],
            yAxes: [ { stacked: true } ]
        }
      }
    },
  },
  mixins: [mixins.reactiveProp],
  mounted () {
      this.renderChart(this.chartData, this.options)
  }
}
