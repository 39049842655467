import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import Vue from 'vue/dist/vue.esm'
import TurbolinksAdapter from 'vue-turbolinks'
import DateRangePicker from 'vue2-daterange-picker'
import jquery from 'jquery'
import bootstrap from 'bootstrap/dist/js/bootstrap'
import BarChart from './bar_chart'
import ObjectiveChart from './objective_chart'
import * as moment from 'moment-timezone'
import '@fortawesome/fontawesome-free/js/fontawesome'
import '@fortawesome/fontawesome-free/js/solid'
import '@fortawesome/fontawesome-free/js/regular'
import Cookies from 'js-cookie'


Rails.start()
Turbolinks.start()
ActiveStorage.start()
Vue.use(TurbolinksAdapter)


var debugging = false
var storedDate = Cookies.get('selectedDate');

console.log("Stored Date", storedDate);
if(storedDate) {
  var defaultStartDate = storedDate
  var defaultEndDate = storedDate
} else {
  var defaultStartDate = moment(new Date()).tz("America/New_York").startOf('day').toDate()
  var defaultEndDate = moment(new Date()).tz("America/New_York").startOf('day').toDate()
}

debugPrint(defaultStartDate)
debugPrint(defaultEndDate)

document.addEventListener('turbolinks:load', function(){
    setupMachineDashboard()
    setupMainDashboard()
    setupDowntimes()
})

function setupDowntimes() {
  document.querySelectorAll('.lw-form-row').forEach(row => {
    var id = row.id;
    var vueRow = new Vue({
      el: "#" + id,
      data: {
        explanation: "",
      }, 
      methods: {

      }
    });
  });
}

function convertTZ(date, tzString) {
    return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}));
}

function debugPrint(x, y) {
    if(debugging){
      console.log(x, y);
    }
}

/* For the graph at the bottom */
async function fetchMachineReadingInternal(machineName, moduleId, reading, startDate, endDate, interval) {
  startDate = startDate.toISOString().split('T')[0]
  endDate = endDate.toISOString().split('T')[0]
    debugPrint("Fetched machine reading " + machineName + " with reading " + reading + " between " + startDate + " and " + endDate);
    const response = await fetch(`/shop_machines/${machineName}/readings?start_date=${startDate}&end_date=${endDate}&interval=${interval}&reading_type=${reading}`);
    var result = response.json();
    debugPrint("Result is",result)
    return result
}


function setupMainDashboard(){
    if(document.querySelector('.main-dashboard')){
        window.debugMachine = new Vue({
            el: ".main-dashboard",
            components: {objectivechart: ObjectiveChart},
            data: {
                loading: false,
                machines: gon.shop_machines,
                machineData: gon.machine_data || [],
                refLineType: 'custom',
                refLineStyles: {'stroke': '#0f0'},
            },
            methods: {
                hasChartData(reading_type, id) {
                    if(Object.keys(gon.stackedData).indexOf(reading_type)>-1 && gon.stackedData[reading_type][id] && gon.stackedData[reading_type][id].datasets[0].data.length < 3){
                        return false;
                    }
                    return true;
                },
                chartFor(reading_type, id) {
                    return gon.stackedData[reading_type][id];
                },
                isActive(id){
                  id = parseInt(id)
                    var active = false;
                    var self = this
                    if(this.machineData[id]){
                        Object.keys(self.machineData[id]).forEach(function(reading){
                            var readings = self.machineData[id][reading]
                            if(readings[readings.length-1]>0){
                                active = true
                                return true;
                            }
                        });
                    }
                    return active;
                },
                readingFor(machine, reading) {
                  machine = parseInt(machine)
                    var sum = 0
                    if(this.machineData[machine] && this.machineData[machine][reading]){
                        Object.values(this.machineData[machine][reading]).forEach(x => sum+=x)
                        return sum
                    } else {
                        return 0;
                    }
                },
            },
            mounted: function(){
                console.log("Reading")
            }
        })
    }
}

function setupMachineDashboard(){
    if(document.querySelector('.machine-dashboard')){
        window.debugMachine = new Vue({
            components: {barchart: BarChart, DateRangePicker: DateRangePicker, objectivechart: ObjectiveChart},
            el: ".machine-dashboard",
            filters: {
                date (value) {
                    if (!value)
                        return ''

                    let options = {year: 'numeric', month: 'long', day: 'numeric'};
                    return Intl.DateTimeFormat('en-IN', options).format(value)
                }
            },
            data: {
                loading: false,
                currentHour: 23,
                hours: 24,
                widthCount: 1,
                machine: gon.shop_machine,
                screenLayout: gon.screen_layout,
                slideshow: gon.slideshow,
                currentSlideIndex: 0,
                tiles: gon.screen_layout.tile_objects,
                startDate: defaultStartDate,
                endDate: defaultEndDate,
                tileData: {},
                lineChartData: {},
                dateRange: {startDate: defaultStartDate, endDate: defaultEndDate},
                maxDate: new Date(),
                renderKey: 0,
            },
            methods: {
                nextSlide() {
                  this.currentSlideIndex+=1;
                  this.currentSlideIndex %= this.tiles.length;
                },
                toggleSlides() {
                  this.slideshow = !this.slideshow;
                  /* Slideshow is activated */
                  if(this.slideshow){
                    this.fetchRemoteData();
                  }
                },
                fetchRemoteData(){
                    this.loading = true
                    debugPrint("Fetching data from Azure...", this.loading)
                    var self = this
                    var toLoad = self.tiles.length
                    var loaded = 0
                    self.tiles.forEach(async tile => {
                        /* Module is currently hard-coded to 0 */
                        var rows = await fetchMachineReadingInternal(self.machine.name, "0", tile.reading, this.startDate, this.endDate, '1h');
                        var minuteRows = await fetchMachineReadingInternal(self.machine.name, "0", tile.reading, this.startDate, this.endDate, '1m');
                        debugPrint("Minute Rows",minuteRows)
                        debugPrint("MR Start Date", this.startDate)
                        debugPrint("MR End Date", this.endDate)
                        console.log("Tile Data for " + tile.id, rows);
                        self.$set(self.tileData, tile.id, rows)
                        self.$set(self.lineChartData, tile.id, minuteRows)
                        loaded+=1
                        debugPrint("Loaded", loaded)
                        if(loaded == toLoad){
                            self.loading = false
                        }
                    });
                },
                updateDates(){
                    debugPrint("Date Range",this.dateRange);
                    this.startDate = this.dateRange.startDate;
                    this.endDate = this.dateRange.endDate;
                    this.startDate = moment(this.startDate).tz("America/New_York").startOf("day").toDate()
                    this.endDate = moment(this.startDate).tz("America/New_York").endOf("day").toDate()
                    Cookies.set('selectedDate', this.startDate, { expires: 1 });
                    this.fetchRemoteData();
                },
                setHour(h) {
                    this.currentHour = h
                },
                getReading(tile) {
                    var now = moment(new Date()).tz("America/New_York")
                    if(moment(now).isSame(this.startDate, 'day') && now.hour() == this.currentHour){
                      console.log("Current hour")
                      var sum = 0;
                      var start_index = (now.hour() * 60) + now.minute() - 60;
                      var end_index = (now.hour() * 60) + now.minute();
                      if(this.lineChartData[tile.id]){
                        for(var i = start_index; i < end_index; i++){
                          sum += this.lineChartData[tile.id][i];
                        }
                        return sum;
                      } else {
                        return "No Data";
                      }
                    } else {
                      var data = this.getTileData(tile)
                      if(data == null){
                        console.log("No ID")
                        return "No Data"
                      }
                    }
                    if(this.currentHour in data){
                      return data[this.currentHour]
                    }
                    return data[`${this.currentHour}`]
                },
                getTileData(tile) {
                    if(tile.id in this.tileData){
                        var data = this.tileData[tile.id]
                        return data
                    } else {
                        return null
                    }
                },
                chartData(tile) {
                  if(this.tileData[tile.id]){
                    var data = this.tileData[tile.id];
                    debugPrint("KEYS", Object.keys(data));
                    var keys = Object.keys(data).filter(x => data[x] !== undefined).map(x => `${x}:00`);
                    var goodData = [];
                    if(data.constructor == Array){
                      goodData = data.filter(x => x!==undefined);
                    } else {
                      goodData = Object.values(data).filter(x => x!==undefined);
                    }

                    return {
                      labels: keys,
                      datasets: [
                        {
                          label: tile.reading,
                          backgroundColor: "#365978",
                          data: goodData,
                        }
                      ]
                    };
                  } else {
                    return {labels: [], datasets: []};
                  }
                },
                updateHour() {
                  this.currentHour = moment(new Date()).tz("America/New_York").hour();
                  this.startDate = moment(new Date()).tz("America/New_York").startOf("day").toDate()
                  this.endDate = moment(new Date()).tz("America/New_York").endOf("day").toDate()
                }
            },
            computed: {
                chartLines() {
                    var data = this.lineChartData[this.tiles[0].id];
                    if(this.$refs.lineGraph && this.widthCount > 0){
                        var width = this.$refs.lineGraph.clientWidth
                        debugPrint("Width is ", width)
                        var minuteWidth = width/1440.0
                        debugPrint("Minute width is: ", minuteWidth)
                    }
                    var pauseWidth = 0;
                    var busyWidth = 0;
                    var drawMode = "pause";
                    var lines = [];
                    var procCount = 0;
                    var widthSum = 0;
                    if(data){
                        for(var i=0;i<1440;i++){
                            /* Encountered Busy */
                            if(data[i] && data[i]!=0){
                                busyWidth+=minuteWidth
                                if(drawMode != "busy"){
                                    //debugPrint("Switched to busy at " + i, pauseWidth);
                                    if(pauseWidth > minuteWidth * 180){
                                      lines.push({status: "sleep", width: Math.ceil(pauseWidth)})
                                    } else {
                                      lines.push({status: drawMode, width: Math.ceil(pauseWidth)})
                                    }

                                    drawMode = "busy"
                                    pauseWidth = 0
                                    busyWidth = minuteWidth
                                }
                            }
                            /* Encountered no activity */
                            else {
                                pauseWidth+=minuteWidth
                                if(drawMode != "pause"){
                                    //debugPrint("Switched to pause at " + i, busyWidth);
                                  lines.push({status: drawMode, width: Math.ceil(busyWidth)})

                                  drawMode = "pause"
                                  pauseWidth = minuteWidth
                                  busyWidth = 0
                                }
                            }
                        }
                        widthSum+=Math.ceil(drawMode == "pause" ? pauseWidth : busyWidth)
                        debugPrint("Widthsum", widthSum)
                        lines.push({status: "sleep", width: drawMode == "pause" ? Math.ceil(pauseWidth) : Math.ceil(busyWidth)})
                    }
                    debugPrint("Lines",lines)
                    return lines;
                }
            },
            mounted: function(){
              var self = this;
              window.addEventListener('resize', function(){
                debugPrint("R")
                self.widthCount+=1
              })
              var self = this;
              document.querySelector('.trigger-slideshow').addEventListener('click',function(){
                debugPrint("Slides")
                self.toggleSlides()
              });
              setInterval(function(){
                if(self.slideshow){
                  self.nextSlide();
                }
              },1000 * 10);
              setInterval(function(){
                self.fetchRemoteData()
                if(self.slideshow){
                  self.updateHour();
                }
              },1000 * 60);
              this.updateHour();
              this.updateDates();
            }
        })
    }
}
